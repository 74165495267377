import { Location } from 'history';
import { Link } from 'react-router-dom';

import { ClickableArea } from './base/Button';
import { StyledCardSection } from './base/Card';
import Icon, { IconName } from './base/Icon';
import Text from './base/Text';
import Tooltip from './base/Tooltip';
import Dropdown, { DropdownProps } from './Dropdown';
import { withStaticProperties } from '../../utils/withStaticProperties';
import { usePermissionAwareDisabled } from '../contexts/TeamPermissionContext';
import { TeamRole } from '../../../../../typings/TeamMember.interface';
import Search from './Search';
import { useState } from 'react';
import { Div } from './helpers/StyledUtils';
import styled, { useTheme } from 'styled-components';
import Loading from './base/Loading';

export interface DropdownMenuOption {
  label: string;
  description?: string;
  icon?: IconName | React.ReactElement;
  danger?: boolean;
  disabled?: boolean;
  tooltip?: string;
  selected?: boolean;
  role?: TeamRole;
  block?: boolean;
  onClick?: () => void;
  to?: string | Partial<Location> | ((location: Location) => string | Partial<Location>);
}

export interface DropdownMenuProps extends Omit<DropdownProps, 'children'> {
  title?: string;
  search?: boolean;
  search_placeholder?: string;
  search_term?: string;
  setSearchTerm?: (value: string) => void;
  search_is_loading?: boolean;
  separator?: boolean;
  options: DropdownMenuOption[];
  search_ref?: React.RefObject<HTMLInputElement>;
}

const StyledScrollableArea = styled(Div)`
  flex-grow: 1;
  overflow-y: auto;
  box-sizing: border-box;
  flex-grow: 1;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const DropdownMenu: React.FC<DropdownMenuProps> = ({
  options,
  title,
  search_ref,
  search: show_search,
  search_placeholder,
  search_term = '',
  search_is_loading = false,
  setSearchTerm,
  ...props
}) => {
  const dropdown_props = { ...props };
  const [search, _setSearch] = useState<string>(search_term);
  const theme = useTheme();

  const setSearch = (value: string) => {
    if (setSearchTerm) {
      setSearchTerm(value);
    }

    _setSearch(value);
  };

  const filtered_options = search
    ? options.filter(
        (option) =>
          option.label.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
          (option.description &&
            option.description.toLowerCase().indexOf(search.toLowerCase()) > -1),
      )
    : options;

  return (
    <Dropdown {...dropdown_props}>
      {(toggle) => (
        <>
          {title && (
            <StyledCardSection p={{ y: 1, x: 3 }} muted>
              <Text size="xs" subtitle text_wrap={false}>
                {title}
              </Text>
            </StyledCardSection>
          )}
          {show_search && (
            <StyledCardSection style={{ borderBottom: props.separator ? undefined : theme.border }}>
              <Search
                borderless
                small
                placeholder={search_placeholder}
                value={search}
                onChange={setSearch}
                search_ref={search_ref}
              />
            </StyledCardSection>
          )}
          {search_is_loading ? (
            <StyledCardSection>
              <Div p={{ x: 3, y: 2 }} flex={{ align: 'center', justify: 'center' }}>
                <Loading />
              </Div>
            </StyledCardSection>
          ) : filtered_options.length > 0 ? (
            <StyledCardSection>
              <StyledScrollableArea max_h={{ px: 200 }}>
                {filtered_options.map((option) => (
                  <DropdownMenuOptionItem
                    key={option.label}
                    toggle={toggle}
                    {...option}
                    disabled={Boolean(option.disabled || dropdown_props.disabled)}
                  />
                ))}
              </StyledScrollableArea>
            </StyledCardSection>
          ) : (
            <StyledCardSection p={{ y: 2, x: 3 }}>
              <Text muted>
                <Icon icon="info" left /> {search ? 'No results' : 'No options'}
              </Text>
            </StyledCardSection>
          )}
        </>
      )}
    </Dropdown>
  );
};

function DropdownMenuOptionItem({
  toggle,
  label,
  description,
  selected,
  icon,
  onClick,
  to,
  tooltip,
  disabled: _disabled,
  danger,
  role,
}: DropdownMenuOption & { toggle: (b: boolean) => void }) {
  const disabled = usePermissionAwareDisabled(_disabled, role);
  const content = (
    <ClickableArea
      {...(to
        ? { to, as: Link, onClick: () => toggle(false) }
        : {
            onClick: (e) => {
              e.preventDefault();
              toggle(false);
              onClick && onClick();
            },
          })}
      disabled={disabled}
      danger={danger}
      p={{ y: 2, x: 3 }}>
      <Text subtitle muted={disabled} danger={danger} flex={{ align: 'center' }}>
        {icon ? (
          typeof icon === 'string' ? (
            <Icon danger={danger} icon={icon} left pointer={!disabled} />
          ) : (
            icon
          )
        ) : null}
        {label}
        {selected && (
          <Icon primary icon="check" right style={{ marginLeft: 'auto' }} pointer={!disabled} />
        )}
      </Text>
      {description && (
        <Text text_wrap muted max_width="256px">
          {description}
        </Text>
      )}
    </ClickableArea>
  );
  return (
    <StyledCardSection key={label}>
      {tooltip ? (
        <Tooltip tooltip={tooltip} placement="right" offset={[0, 0]}>
          {content}
        </Tooltip>
      ) : (
        content
      )}
    </StyledCardSection>
  );
}

export const DropdownMenuPermission: React.FC<
  React.ComponentPropsWithRef<typeof DropdownMenu> & { role?: TeamRole }
> = ({ role, ...dropdownMenuProps }) => {
  const disabled = usePermissionAwareDisabled(dropdownMenuProps.disabled, role);

  return <DropdownMenu {...dropdownMenuProps} disabled={disabled} />;
};

export default withStaticProperties(DropdownMenu, {
  Permission: DropdownMenuPermission,
});
