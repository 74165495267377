import { FC, MouseEvent, ReactNode } from 'react';
import * as RadixTooltip from '@radix-ui/react-tooltip';
import styled, { css, useTheme } from 'styled-components';

import { copyTextToClipboard } from '../../../utils/copy';
import { Div } from '../helpers/StyledUtils';
import { useToasts } from '../Toast';
import Button from './Button';
import Icon, { IconName } from './Icon';
import Text from './Text';

const StyledTooltip = styled.div(
  ({ theme }) => css`
    max-width: 344px;
    word-break: break-word;
    white-space: initial;
    width: max-content;
    background-color: ${theme.colors.surface.base.surface};
    border: ${theme.border};
    box-shadow: ${theme.elevation[3]};
    border-radius: ${theme.radius.normal};
    padding: ${theme.spacing(1.5)} ${theme.spacing(3)};
  `,
);

// New Tooltip component based on Radix Tooltip but keeping the same API
const Tooltip: FC<{
  open?: boolean;
  align?: 'right' | 'left';
  disabled?: boolean;
  placement?: 'right' | 'bottom-end' | 'bottom-start';
  tooltip: ReactNode;
  children: ReactNode;
  copyable?: boolean;
  offset?: [number, number];
  fill?: boolean;
  cta?: {
    icon?: IconName;
    label: string;
    to: string;
  };
}> = ({
  open,
  tooltip,
  placement = 'right',
  align = 'end',
  children,
  copyable,
  disabled,
  cta,
  offset,
  fill,
}) => {
  const { addToast } = useToasts();
  const theme = useTheme();
  const cta_link_is_external = cta?.to.startsWith('http');

  const handleCopyToClipboard = (e: MouseEvent<HTMLParagraphElement>) => {
    e.stopPropagation();

    copyTextToClipboard(String(tooltip)).then((success) =>
      success
        ? addToast('success', `Copied '${String(tooltip)}' to clipboard`)
        : addToast('error', 'Failed to copy'),
    );
  };
  const tooltip_align = align === 'left' ? 'end' : 'start';
  const tooltip_side =
    placement === 'right'
      ? 'right'
      : placement === 'bottom-end'
        ? 'bottom'
        : placement === 'bottom-start'
          ? 'bottom'
          : undefined;
  return (
    <RadixTooltip.Provider delayDuration={50}>
      <RadixTooltip.Root open={disabled === true ? false : open}>
        <RadixTooltip.Trigger disabled={disabled} asChild>
          <Div style={{ display: fill ? 'block' : 'inline-block', width: fill ? '100%' : 'auto' }}>
            {children}
          </Div>
        </RadixTooltip.Trigger>
        <RadixTooltip.Portal>
          <RadixTooltip.Content
            style={{ zIndex: theme.zindex.tooltip }}
            align={tooltip_align}
            side={tooltip_side}
            sideOffset={offset ? offset[1] : 6}
            alignOffset={offset ? offset[0] : 0}>
            <StyledTooltip onClick={copyable ? handleCopyToClipboard : undefined}>
              <Div
                flex={{ direction: 'column', justify: 'space-between', gap: 2, align: 'center' }}
                m={{ t: cta ? 1.5 : 0 }}>
                <Text>{tooltip}</Text>
                {copyable && <Icon icon="copy" right />}
              </Div>
              {cta && (
                <Button
                  as={cta_link_is_external ? 'a' : undefined}
                  block
                  outline
                  target={cta_link_is_external ? '_blank' : undefined}
                  to={cta_link_is_external ? undefined : cta.to}
                  href={cta.to}
                  icon={cta.icon || 'docs'}
                  m={{ t: 2, b: 1.5 }}>
                  {cta.label}
                </Button>
              )}
            </StyledTooltip>
          </RadixTooltip.Content>
        </RadixTooltip.Portal>
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  );
};

export default Tooltip;
